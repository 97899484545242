
.myTypist {
    font-size: 16px;
    color: red;
}

.privacyPol1 {
    font-size: 12px;
    color: rgb(126, 126, 126);
}

.footerText {
    font-size: 13px;
    color: rgb(126, 126, 126);
}

.align-items-end {
    align-items: end;
}

.k-challenge-bg {
    background-image:  url("../images/challenge-bg.jpg");
    background-size: cover;
    background-position: center center;
}

.k-bg-white {
    background-color: white;
}

.navbar-transition {
    transition: background-color 0.5s ease;
  }

.k-scale-80 {
    scale: 0.8;
}

.k-scale-70 {
    scale: 0.7;
}